.container {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
}

.freeTopBanner {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  background-color: black;
  flex-wrap: wrap;
}

.freeItem {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}

.freeIcon {
  height: 44px;
  width: 111px;
  padding: 8px;
  color: #000;
}

.contentArea {
  font-family: 'Open Sans', sans-serif;
  display: flex;
  background-color: #9ac1ff;
  padding: 60px 20px;
  min-height: 300px;
  position: relative;
}

.innerContainer {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  gap: 60px;
  
  @media screen and (max-width: 992px) {
    gap: 40px;
  }
  
  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 60px;
  }
}

.leftContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
}

.mainHeading {
  font-size: 60px;
  font-weight: 900;
  color: #000;
  line-height: 1.1;
  margin: 0;
  margin-bottom: 20px;
  
  @media screen and (max-width: 992px) {
    font-size: 50px;
  }
  
  @media screen and (max-width: 768px) {
    font-size: 42px;
  }
  
  @media screen and (max-width: 480px) {
    font-size: 36px;
  }
}

.subHeading {
  font-size: 28px;
  color: #000;
  line-height: 1.3;
  margin: 0;
  font-weight: 600;
  
  @media screen and (max-width: 992px) {
    font-size: 24px;
  }
  
  @media screen and (max-width: 768px) {
    font-size: 22px;
  }
  
  @media screen and (max-width: 480px) {
    font-size: 20px;
  }
}

.rightContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.whyJoinHeading {
  font-size: 32px;
  font-weight: 700;
  color: #000;
  margin: 0 0 20px 0;
  
  @media screen and (max-width: 992px) {
    font-size: 30px;
  }
  
  @media screen and (max-width: 768px) {
    font-size: 28px;
  }
  
  @media screen and (max-width: 480px) {
    font-size: 26px;
  }
}

.benefitsList {
  list-style-type: none;
  padding: 0;
  margin: 0;
  
  li {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    margin-bottom: 15px;
    font-size: 18px;
    line-height: 1.4;
    
    &:last-child {
      margin-bottom: 0;
    }
    
    @media screen and (max-width: 480px) {
      font-size: 16px;
    }
  }
}

.icon {
  font-size: 22px;
  display: inline-block;
  min-width: 24px;
}