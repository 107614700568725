.container {
  padding: 4rem 10%;
  background-color: #E7E5FA; // Light purple/blue background as shown in the image
  color: #0d1d3a; // Dark navy blue text color
  
  @media screen and (max-width: 1024px) {
    padding: 3rem 7%;
  }
  
  @media screen and (max-width: 786px) {
    padding: 2.5rem 5%;
  }
  
  @media screen and (max-width: 480px) {
    padding: 2rem 5%;
  }
  
  h2 {
    text-align: left;
    font-weight: 700;
    margin-bottom: 2rem;
    font-size: 40px;
    font-family: 'Open Sans', sans-serif;
    
    @media screen and (max-width: 786px) {
      font-size: 32px;
      margin-bottom: 1.5rem;
    }
    
    @media screen and (max-width: 480px) {
      font-size: 28px;
      margin-bottom: 1.2rem;
    }
  }
  
  .benefitsList {
    ul {
      list-style-type: disc;
      padding-left: 1.5rem;
      font-family: 'Poppins', sans-serif;
      
      li {
        font-size: 20px;
        margin-bottom: 1rem;
        line-height: 1.5;
        font-weight: 500;
        
        @media screen and (max-width: 786px) {
          font-size: 18px;
          margin-bottom: 0.8rem;
        }
        
        @media screen and (max-width: 480px) {
          font-size: 16px;
          margin-bottom: 0.7rem;
        }
      }
    }
  }
  
  .badgeContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    
    @media screen and (max-width: 786px) {
      margin-top: 1.5rem;
    }
  }
  
  .badgeImage {
    max-width: 100%;
    height: auto;
    
    // Adjust badge size based on screen size
    @media screen and (min-width: 1200px) {
      width: 400px;
    }
    
    @media screen and (max-width: 1199px) and (min-width: 787px) {
      width: 250px;
    }
    
    @media screen and (max-width: 786px) {
      width: 220px;
    }
  }
}